import React, { useState } from 'react'
import { getSearchParams } from 'gatsby-query-params'

import LanguageTabs, { getLanguage, useLang } from '../components/LanguageTabs'

import Accordion from '../components/Accordion'
import faqs_EN from '../content/landingPages/faqs_EN'
import faqs_ES from '../content/landingPages/faqs_ES'
import faqs_onco_EN from '../content/landingPages/faqs_onco_EN'
import faqs_onco_ES from '../content/landingPages/faqs_onco_ES'

const content = {
  EN: faqs_EN,
  ES: faqs_ES,
  onco_EN: faqs_onco_EN,
  onco_ES: faqs_onco_ES,
}

const FAQsLandingPage = () => {
  const { sp } = getSearchParams()

  const language = getLanguage()

  const lang = (section) => {
    return sp === 'onco' ? content[`onco_${language}`][section] : content[language][section]
  }

  const faqs = lang('questions')

  const initialActiveIndices = Array(faqs.length).fill([])
  const [activeIndices, setActiveIndices] = useState(initialActiveIndices)

  const collapseAll = () => {
    setActiveIndices([])
  }
  const expandAll = () => {
    const expandedIndices = faqs.map((_, i) => i)
    setActiveIndices(expandedIndices)
  }

  return (
    <main className="faqs-landing-page">
      <div className="welcome-hero">
        <div className="welcome-hero-text">{lang('heading')}</div>
      </div>

      <div className="landing-page-section">
        <div className="landing-page-section-content">
          <LanguageTabs />

          <div className="faq-collapse-actions">
            <button className="action" onClick={collapseAll}>
              Collapse All
            </button>
            <div>|</div>
            <button className="action" onClick={expandAll}>
              Expand All
            </button>
          </div>
          <Accordion className="faq-content" panels={faqs} activeIndices={activeIndices} />
          <div className="help">
            <h2>{lang('Help').heading}</h2>
            <p>
              {lang('Help').content}
              <br />
              <br />
              {lang('Help').mon}
              <br />
              {lang('Help').fri}
              <br />
              {lang('Help').sat}
            </p>
          </div>
        </div>
      </div>
    </main>
  )
}

export default FAQsLandingPage

import rechargeBottle from '../../images/recharge-bottle.png'

export default {
  heading: 'Frequently asked questions',
  questions: [
    {
      title: `How much will it cost me to use Aidia?`,
      content: `<p>Nothing. Your Aidia System is likely sponsored by the manufacturer of your medication or healthcare organization, which allows us to deliver this service at no cost to you.</p>`,
    },
    {
      title: `What happens if I do not take my medication on time?`,
      content: `<p>If you or your loved one misses a dose, reminder alerts kick in. Reminder alerts (bottle chimes, texts, and/or phone calls) are designed to make sure that a dose is taken as soon as possible.</p>`,
    },
    {
      title: `How can I change my dose times or settings?`,
      content: `<p>Just call your pharmacy. They can update your dose times, pause reminders, and personalize your bottle or phone settings. Simply call Onco360 Oncology Pharmacy at (877) 662-6633.</p>`,
    },
    {
      title: `What happens when I get a prescription refill?`,
      content: `<p>When you receive your refill, transfer the medication into your Aidia Smart Bottle. Wait until your next scheduled dose time to make the transfer, since opening the bottle will be counted as taking a dose—and that may throw off your schedule.</p>
      <p>Keep and refer to the pharmacy prescription label for your medication directions of use and any other medication details that you may need to refer to, like the medicine’s Patient Prescribing Information.</p>`,
    },
    {
      title: `Why is the bottle glowing before my dose time?`,
      content: `<p>That blue glow appears one hour before it is time for your dose. Consider this a gentle reminder to take your medicine within the next hour.</p>
      <p>If an hour goes by and you still haven’t taken your medication, the reminder alerts will stop. Instead, you will receive a phone call or text message checking in on you and why you missed your dose.</p>`,
    },
    {
      title: `When do I need to recharge the bottle?`,
      content: `<p>Your smart bottle arrives charged. At full charge, the bottle has enough power for up to 10 months. You will receive a low battery message by text or call when it’s time to recharge.</p>`,
    },
    {
      title: `How do I recharge the bottle?`,
      content: `<p>Lift the black rubber flap on the bottom of the bottle and look for the charging port. Insert the charger plug into the bottle and plug into a power outlet.</p>
      <p><div class="photo"><img src=${rechargeBottle} alt="recharge bottle"/></div></p>
      <p>Once plugged in and charging, the bottle will glow purple. The glow changes to green when it is fully charged. Your bottle should be fully charged within 8 hours.</p>`,
    },
    {
      title: `Can I travel with the bottle?`,
      content: `<p>Absolutely. Your Aidia Smart Bottle can be packed in a checked bag or carry-on and works around the world with cellular service.</p>`,
    },
    {
      title: `How can my caregiver also receive text or phone reminders?`,
      content: `<p>Your pharmacy can help you with that. Give them a call to add a caregiver phone number within minutes.</p>`,
    },
  ],
  Help: {
    heading: `Help is at your fingertips`,
    content: `If at any point you’d like to change your bottle’s settings or dose schedule, contact Onco360 Oncology Pharmacy at (877) 662-6633.`,
  },
}

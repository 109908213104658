import rechargeBottle from '../../images/recharge-bottle.png'

export default {
  heading: 'Preguntas frecuentes',
  questions: [
    {
      title: `¿Cuánto me costará utilizar Aidia?`,
      content: `<p>Nada. Es probable que su sistema Aidia esté patrocinado por el fabricante de su medicamento u organización de atención médica, lo que nos permite brindarle este servicio sin costo para usted.</p>`,
    },
    {
      title: `¿Qué sucede si no tomo mis medicamentos a tiempo?`,
      content: `<p>Si usted o su ser querido omiten una dosis, se activan las alertas de recordatorio. Las alertas de recordatorio (campanillas, mensajes de texto y/o llamadas telefónicas) están diseñadas para garantizar que se tome una dosis lo antes posible.</p>`,
    },
    {
      title: `¿Cómo puedo cambiar los horarios o la configuración de mi dosis?`,
      content: `<p>Simplemente llame a su farmacia o comuníquese con un especialista de soporte de Aidia. Podemos actualizar sus tiempos de dosis, pausar recordatorios y personalizar la configuración de su frasco o teléfono. Simplemente llame al número impreso en la parte inferior de su frasco para mayor comodidad.</p>`,
    },
    {
      title: `¿Qué sucede cuando obtengo un reabastecimiento de una receta?`,
      content: `<p>Cuando reciba su reposición, transfiera el medicamento a su frasco Aidia. Espere hasta su próxima dosis programada para hacer la transferencia, ya que abrir el frasco se contará como tomar una dosis, y eso puede alterar su horario.</p>
    <p>Conserve y consulte la etiqueta de la receta de la farmacia para conocer las instrucciones de uso de su medicamento (y cualquier otro detalle del medicamento al que deba consultar, como la Información de prescripción del medicamento para el paciente).</p>`,
    },
    {
      title: `¿Por qué mi frasco está brillando antes de la hora de mi dosis?`,
      content: `<p>Ese resplandor azul aparece una hora antes de la hora de su dosis. Considere esto como un suave recordatorio para tomar su medicamento dentro de la próxima hora.</p>
    <p>Si pasa una hora y aún no ha tomado su medicamento, las alertas de recordatorio se detendrán. En su lugar, recibirá una llamada telefónica o un mensaje de texto para verificar su estado y por qué omitió su dosis.</p>`,
    },
    {
      title: `¿Cuándo necesito recargar el frasco?`,
      content: `<p>Su frasco inteligente llega cargado. Con carga completa, el frasco tiene batería suficiente para hasta 10 meses. Recibirá un mensaje de batería baja por texto o llamada cuando sea el momento de recargar.</p>`,
    },
    {
      title: `¿Cómo recargo mi frasco?`,
      content: `<p>Levante la solapa de goma negra en la parte inferior de la botella y busque el puerto de carga. Inserte el enchufe del cargador en el frasco y conéctelo a una toma de corriente.</p>
    <p><div class="photo"><img src=${rechargeBottle} alt="recharge bottle"/></div></p>
    <p>Una vez enchufado y cargando, el frasco brillará en color púrpura. El brillo cambia a verde cuando está completamente cargado. Su frasco debe estar completamente cargado dentro de las 8 horas.</p>`,
    },
    {
      title: `¿Puedo viajar con mi frasco?`,
      content: `<p>Absolutamente. Su frasco inteligente Aidia se puede empacar en una maleta facturada o en el equipaje de mano y funciona en todo el mundo con servicio celular.</p>`,
    },
    {
      title: `¿Cómo puede mi cuidador también recibir recordatorios por mensaje de texto o por teléfono?`,
      content: `<p>Un especialista en soporte de Aidia puede ayudarlo con eso. Llámenos para agregar un número de teléfono para el cuidador en cuestión de minutos.</p>`,
    },
  ],
  Help: {
    heading: `Ayuda al alcance de su mano`,
    content: `Si en algún momento desea cambiar la configuración de su frasco o el horario de su dosis, llame al servicio de asistencia al 1-800-381-9384.`,
    mon: `lunes a jueves (8 am a 8 pm ET)`,
    fri: `viernes (8 am a 6 pm ET)`,
    sat: `sábado–domingo (11 am a 5 pm ET)`,
  },
}
